import React, { Component, ErrorInfo } from "react";
import Router from "next/router";
import * as Sentry from "@sentry/nextjs";
import { CurrentOrganizationQuery } from "~graphql/sdk";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  organization: CurrentOrganizationQuery["currentOrganization"];
}
export default class ErrorBoundary extends Component<ErrorBoundaryProps> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(
      "Caught an error:",
      this.props.organization,
      error,
      errorInfo,
      Router.asPath
    );
    Sentry.captureException(
      {
        error,
        errorInfo,
        path: Router.asPath,
        organisation: this.props.organization,
      },
      {
        tags: {
          runtimeError: true,
        },
      }
    );
    // Navigate to the custom error page
    void Router.replace("/_error", Router.asPath);
  }
  public render() {
    return this.props.children;
  }
}
