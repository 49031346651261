import { useContext } from "react";
import { useSessionFeatureFlags } from "~components/FeatureFlagPanel";
import { domainAllowsSessionFeatureFlags } from "~config";
import { BaseContext } from "~context";
import { OrganizationFeatures } from "~lib";

type Features = `${OrganizationFeatures}`;

export const useOrganization = () => {
  const ctx = useContext(BaseContext);
  const { featureFlags } = useSessionFeatureFlags();

  if (ctx === undefined) {
    throw new Error("Context must be used within a provider!");
  }

  const hasFeature = (feature: Features): boolean => {
    return domainAllowsSessionFeatureFlags
      ? !!featureFlags[feature]
      : ctx?.organization?.features?.includes(feature);
  };

  return { organization: ctx?.organization, hasFeature };
};
