import {
  IReleaseTicket,
  IReleaseZone,
} from "~features/nonseated-reservation/nonseated-reservation.types";

export const isVisible = function (
  selectedTicketTypes,
  entity,
  purchaseHistory = []
) {
  if (!entity?.purchaseRestrictions) {
    return true;
  }

  for (const restriction of entity.purchaseRestrictions) {
    if (restriction.isAlwaysVisible) {
      return true;
    }
  }

  return isAvailableForSelection(selectedTicketTypes, entity, purchaseHistory);
};

export const isAvailableForSelection = function (
  selectedTicketTypes,
  entity,
  purchaseHistory = []
) {
  if (!entity?.purchaseRestrictions) {
    return true;
  }

  if (!entity.purchaseRestrictions.length) {
    return true;
  }

  for (const restriction of entity.purchaseRestrictions) {
    if (hasRequired(selectedTicketTypes, restriction, purchaseHistory)) {
      return true;
    }
  }

  return false;
};

const hasRequired = function (
  selectedEntities,
  restriction,
  purchaseHistory = []
) {
  for (const requiredEntity of restriction.requiredEntities) {
    const requiredTicketType = selectedEntities.find(
      (tt) => tt.id === requiredEntity.requiredId
    );

    if (
      !requiredTicketType ||
      requiredTicketType.quantity < restriction.quantity
    ) {
      const requiredTicketType = purchaseHistory.find((history) =>
        [history.ticketType?.id, history.membershipType?.id].includes(
          requiredEntity.requiredId
        )
      );

      if (
        !requiredTicketType ||
        requiredTicketType.total < restriction.quantity
      ) {
        return false;
      }
    }
  }

  return true;
};

export function createZoneVisibilityFilter(props: {
  selectedTickets: IReleaseTicket[];
  ticketsSoldForUser: any[];
  isPresaleRelease: boolean;
  isAdmin: boolean;
}) {
  const {
    selectedTickets,
    ticketsSoldForUser,
    isPresaleRelease,
    isAdmin,
  } = props;

  const zoneVisibilityFilter = (zone: IReleaseZone) => {
    if (!zone.isActive) {
      return null;
    }

    return zone.ticketTypes.some((ticketType) => {
      if (!ticketType.isActive && !isPresaleRelease) {
        return false;
      }

      if (
        !isVisible(selectedTickets, ticketType, ticketsSoldForUser) &&
        !isAdmin
      ) {
        return false;
      }

      return true;
    });
  };

  return zoneVisibilityFilter;
}
