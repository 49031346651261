import { OrderQuery } from "~graphql/sdk";

export const getPaymentCount = (
  method: string,
  order: OrderQuery["order"]
): number => {
  let paymentCount = 1;
  if (method?.includes("-variant-")) {
    const variantId = method.split("-variant-")[1];

    paymentCount =
      order?.event?.paymentPlanSettings?.scheduleOptions.find(
        (option) => option?.id === variantId
      )?.installmentCount ?? 1;
  }

  return paymentCount;
};
