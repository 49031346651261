import { BuyNowPayLaterConfig } from "~features/checkout/checkout.types";
import { IReleaseTicket } from "~features/nonseated-reservation/nonseated-reservation.types";
import { FeeType } from "~graphql/sdk";

export type OmitTypename<T> = Omit<T, "__typename">;

export const calculateMultibuyDiscount = (
  // TODO: promo is a custom type because of useNonSeatedMultibuy type narrowing.
  // this  can be updated but need to consider that the hook is used on the checkout page.
  promo: any,
  allTypes: IReleaseTicket[],
  zoneTypes: IReleaseTicket[]
) => {
  const ticketPrice = (getCurrentZoneType(
    promo?.getType?.id,
    allTypes,
    zoneTypes
  )?.price ??
    promo?.getType?.price ??
    0) as number;

  if (promo?.promotion?.price > 0) {
    const multiBuyItemPrice = Math.min(
      ticketPrice,
      promo.promotion.price as number
    );

    if (promo.promotion.price > ticketPrice) {
      return 0;
    }

    return (ticketPrice - multiBuyItemPrice) * promo.giftedQty;
  }

  return (promo?.giftedQty || 0) * ticketPrice;
};

type optionsConfig = {
  fee?: number | null;
  type?: FeeType | null;
  showHiddenFees?: boolean;
};

export const calculateTotal = (
  items: IReleaseTicket[],
  options: optionsConfig = {}
): number => {
  const total: number = items.reduce((acc, item) => {
    let currentPrice = item.price ?? 0;
    let ticketFee = 0;
    let stadiumLevyFee = 0;

    if (options.showHiddenFees && item.stadiumLevyFee) {
      stadiumLevyFee = item.stadiumLevyFee;
    }

    if (options.showHiddenFees && item.ticketFee) {
      if (item.ticketFeeType === FeeType.Percentage) {
        ticketFee = currentPrice * (item.ticketFee / 100);
      } else {
        ticketFee = item.ticketFee;
      }
    }

    currentPrice += ticketFee + stadiumLevyFee;

    return acc + currentPrice * item.quantity;
  }, 0);

  let bookingFee = 0;

  if (options.showHiddenFees && options.fee) {
    if (options.type === FeeType.Percentage) {
      bookingFee = total * (options.fee / 100);
    } else {
      bookingFee = options.fee;
    }
  }

  return total > 0 ? total + bookingFee : total;
};

export const calculateBNPLPrice = (
  total: number,
  config: BuyNowPayLaterConfig,
  options: optionsConfig = {}
): number => {
  let fee = 0;

  if (options.showHiddenFees && options.fee) {
    if (options.type === FeeType.Percentage) {
      fee = total * (options.fee / 100);
    } else {
      fee = options.fee;
    }
  }

  return (total + fee) / config.terms;
};

function getCurrentZoneType(
  id: string,
  allTypes: IReleaseTicket[],
  zoneTypes: IReleaseTicket[]
) {
  return zoneTypes?.find((zoneType) => {
    const currentType = allTypes?.find((type) => type?.id === id);

    return zoneType?.id === currentType?.id;
  });
}
