import { isSameDay } from "date-fns";
import { formatDate } from "./formatDate";
import { Event } from "~graphql/sdk";

const DATE_FORMAT_FULL = "d MMM yyy, h:mma";

type EventDates = Omit<Event["dates"][number], "id">[];

// Can't seem to find a way to get date-fns to format AM/PM as lowercase so this works for now.
function formatToString(
  date: Date | string,
  format: string = DATE_FORMAT_FULL
) {
  return formatDate(date, format).replace("PM", "pm").replace("AM", "am");
}

/**
 * Output examples:
 * 5 Sep 2023, 9:05am - 4:34pm
 * 3 Sep 2023, 1:45pm - 5 Sep 2023, 4:34pm
 */
export default function formatDateRange({
  start,
  end,
}: {
  start: Date | string;
  end?: Date | string;
}) {
  if (!end) {
    return formatToString(start);
  }

  if (isSameDay(new Date(start), new Date(end))) {
    return formatToString(start) + " - " + formatToString(end, "h:mma");
  }
  return formatToString(start) + " - " + formatToString(end);
}

export function formatEventDateRange(dates: EventDates) {
  return formatDateRange({
    start: earliestStartDate(dates),
    end: greatestEndDate(dates),
  });
}

export function formatEventAllDates(dates?: EventDates) {
  return dates?.map((date) =>
    formatDateRange({
      start: date.startDate,
      end: date.endDate,
    })
  );
}

export function greatestEndDate(eventDates: EventDates) {
  return eventDates.reduce((latest, { endDate }) => {
    if (endDate > latest) {
      return endDate;
    }
    return latest;
  }, eventDates[0].endDate);
}

export function earliestStartDate(eventDates: EventDates) {
  return eventDates.reduce((earliest, { startDate }) => {
    if (startDate < earliest) {
      return startDate;
    }
    return earliest;
  }, eventDates[0].startDate);
}
