import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import NextImage, { ImageProps } from "next/future/image";
import { getImageSrc } from "~lib";
import { Box, SystemProps, system } from "flicket-ui";
import { ExtendedFile, MetaData } from "~graphql/sdk";
import styled from "styled-components";
import { omit, pick } from "@styled-system/props";
import { ImageWrapper } from "../common.ImageWrapper";

export interface ImageWrapperProps
  extends Omit<ImageProps, "src" | "width" | "height"> {
  src?: string;
  image?: ExtendedFile;
  style?: React.CSSProperties;
  fallback?: string;
  metaData?: MetaData;
}

export const StyledDiv = styled.div<SystemProps>`
  overflow: hidden;
  ${system}
`;

const hasMetaData = (bannerImage: ExtendedFile) =>
  bannerImage?.metaData?.width && bannerImage?.metaData?.height;

// This component uses next/image under the hood to render the image.
// The component works with images that have metadata, or it reads the
// width of the current container and uses that to supply to next/image
const Image: FC<ImageWrapperProps & SystemProps> = ({
  image,
  src,
  alt,
  style = {},
  fallback = "",
  ...rest
}) => {
  const wrapper = useRef<HTMLDivElement>();
  const [contentSize, setContentSize] = useState<MetaData>(image?.metaData);

  useEffect(() => {
    if (wrapper.current) {
      setContentSize({
        width: wrapper.current.offsetWidth,
        height: wrapper.current.offsetHeight,
      });
    }
  }, [wrapper.current]);

  const imageSrc = src ?? getImageSrc(image, fallback);

  if (!imageSrc) {
    return null;
  }

  return (
    <StyledDiv ref={wrapper} {...pick(rest)}>
      {contentSize && (
        <NextImage
          width={contentSize.width}
          height={contentSize.height}
          style={{
            width: "100%",
            height: "auto",
            ...style,
          }}
          src={imageSrc}
          alt={alt}
          {...omit(rest)}
        />
      )}
    </StyledDiv>
  );
};

interface IFixedSizeImageProps
  extends ImageWrapperProps,
    Omit<
      SystemProps,
      "height" | "width" | "color" | "fill" | "objectFit" | "objectPosition"
    > {
  objectFit?: CSSProperties["objectFit"];
  objectPosition?: CSSProperties["objectPosition"];
  height: number;
  width: number;
}

function FixedSizeImage({
  image,
  src,
  alt,
  fallback = "",
  width,
  height,
  objectPosition = "center",
  objectFit = "contain",
  ...props
}: IFixedSizeImageProps) {
  const imageSrc = src ?? getImageSrc(image, fallback);

  return (
    <ImageWrapper width={width} height={height} {...pick(props)}>
      <NextImage
        src={imageSrc}
        alt={alt}
        fill={true}
        style={{ objectFit, objectPosition }}
        {...omit(props)}
      />
    </ImageWrapper>
  );
}

export interface IFixedRatioImageProps
  extends Omit<IFixedSizeImageProps, "width" | "height"> {
  /** aspect ratio expressed a `"width / height"` */
  aspectRatio: CSSProperties["aspectRatio"];
  width?: SystemProps["width"];
  height?: SystemProps["height"];
  useNearFitCover?: boolean;
  nearFitThresholdPercent?: number;
}

function FixedRatioImage({
  image,
  src,
  metaData,
  alt,
  fallback = "",
  aspectRatio,
  objectPosition = "center",
  objectFit = "cover",
  useNearFitCover = false,
  nearFitThresholdPercent = 5,
  ...props
}: IFixedRatioImageProps & Omit<SystemProps, "objectFit" | "objectPosition">) {
  const imageSrc = src ?? getImageSrc(image, fallback);

  if (!imageSrc) {
    return null;
  }

  const fitType = (() => {
    if (useNearFitCover) {
      return getNearFitType(
        metaData ?? image?.metaData,
        aspectRatio,
        nearFitThresholdPercent,
        objectFit
      );
    }

    return objectFit;
  })();

  return (
    <Box
      position="relative"
      overflow="hidden"
      css={{
        aspectRatio,
      }}
      height="auto"
      {...pick(props)}
    >
      <NextImage
        src={imageSrc}
        alt={alt}
        fill={true}
        style={{
          objectFit: fitType,
          objectPosition,
        }}
        {...omit(props)}
      />
    </Box>
  );
}

export default Image;
export { FixedSizeImage, FixedRatioImage };

function getImageAspectRatio(metaData: MetaData) {
  if (!metaData?.width || !metaData?.height) return;

  const { width, height } = metaData;

  if (width > height) {
    return width / height;
  }
}

function calculatePercentageDifference(value1, value2) {
  const difference = value1 - value2;
  return Math.abs((difference / value2) * 100);
}

function getNearFitType(
  metaData: MetaData,
  aspectRatio: CSSProperties["aspectRatio"],
  thresholdPercent: number,
  defaultFit: CSSProperties["objectFit"] = "cover"
) {
  const imageAspectRatio = getImageAspectRatio(metaData);
  // `eval` idea taken from https://stackoverflow.com/a/7142720
  const parsedAspectRatio = eval(aspectRatio as string) as number;
  const percentageDiff = calculatePercentageDifference(
    imageAspectRatio,
    parsedAspectRatio
  );

  return percentageDiff < thresholdPercent ? "cover" : defaultFit;
}
