import {
  CurrentOrganizationQuery,
  EventQuery,
  GetI18nQuery,
} from "~graphql/sdk";

// Used to store global state.
// Initially created to store the organization after
// it has been loaded on the server so we don't have to load it on
// every page load.

type StoreProps = {
  organization: CurrentOrganizationQuery["currentOrganization"] | null;
  host: string;
  event?: EventQuery["event"];
  i18n?: GetI18nQuery["getI18n"]["data"];
};
export const store: StoreProps = {
  organization: null,
  host: "",
  event: null,
  i18n: null,
};
