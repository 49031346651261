import { useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga4";
import { atom, PrimitiveAtom, useAtom } from "jotai";
import { isGAInitialisedAtom } from "./useIsGAInitialised";

export const pixelIdAtom = atom<string | undefined>(undefined) as PrimitiveAtom<
  string | undefined
>;

declare interface DataLayerWindow extends Window {
  fbq: any;
  GA_INITIALIZED?: boolean;
}
declare const window: DataLayerWindow;

interface UseThirdpartyScriptsProps {
  gtmId: string;
}

const ENABLE_TRACKING_SCRIPTS =
  process.env.NODE_ENV === "production" && typeof window !== "undefined";
const HOTJAR_ID = process.env.HOTJAR_PRODUCTION_ID;

export const useThirdpartyScripts = ({ gtmId }: UseThirdpartyScriptsProps) => {
  const [isGAInitialised, setGAInitialised] = useAtom(isGAInitialisedAtom);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && HOTJAR_ID) {
      hotjar.initialize((HOTJAR_ID as unknown) as number, 6);
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && !!gtmId) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", gtmId);
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS) {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-WXVNCRX");
    }
  }, []);

  useEffect(() => {
    if (ENABLE_TRACKING_SCRIPTS && typeof window !== "undefined") {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j: any = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.defer = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-MSK3NHN");
    }
  }, []);

  useEffect(() => {
    if (
      !isGAInitialised &&
      process.env.GA_MEASUREMENT_ID &&
      ENABLE_TRACKING_SCRIPTS
    ) {
      ReactGA.initialize(process.env.GA_MEASUREMENT_ID);
      window.GA_INITIALIZED = true;
      setGAInitialised(true);
    }
  }, []);
};

export const useFacebookPixelScripts = (pixelId: string) => {
  const [, setPixelId] = useAtom(pixelIdAtom);

  useEffect(() => {
    if (pixelId) {
      setPixelId(pixelId);
    }
  }, [pixelId]);

  useEffect(() => {
    if (pixelId) {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function (...args) {
          n.callMethod ? n.callMethod(...args) : n.queue.push(args);
        };
        if (!(f as any)._fbq) (f as any)._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.defer = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      // disable autoConfig events like MicroData or SubscribedButtonClick
      // since they will only be sent to the first loaded pixel(organisation pixel).
      // https://developers.facebook.com/docs/meta-pixel/advanced/#automatic-configuration
      window.fbq("set", "autoConfig", false, pixelId);
      window.fbq("init", pixelId);
      // disable implicit PageView since they will be sent to all the pixels.
      // https://stackoverflow.com/a/38144118
      window.fbq.disablePushState = true;
      window.fbq("trackSingle", pixelId, "PageView");
    }
  }, [pixelId]);
};
